import React, { useState } from 'react';
import './Search.css';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.thehummingbird.org.in/testsearch.php?q=${query}`);
      const data = await response.json();
      setResults(data.organic_results || []);
    } catch (err) {
      setError('Failed to load search results');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='search-box'>
<div className="search-container">
      <h1>Search Ambulance</h1>
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Ambulance Service in Assam"
          className="search-input"
        />
        <button type="submit" className="search-button">Search</button>
      </form>

      {loading && <div className="loading">Loading...</div>}
      {error && <div className="error-message">Error: {error}</div>}

      <div className="results-container">
        {results.map((result, index) => (
          <div key={index} className="result-item">
            <a href={result.link} target="_blank" rel="noopener noreferrer" className="result-title">
              {result.title}
            </a>
            <p className="result-snippet">{result.snippet}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Search;
