import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import Donate from './pages/donate/Donate';
import AdvertiseWithUs from './pages/advertisewithus/AdvertiseWithUs';
import BookAmbulance from './pages/bookambulance/BookAmbulance';
import RegisterAmbulance from './pages/registerambulance/RegisterAmbulance';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Events from './pages/events/Events';
import Media from './pages/media/Media';
import Achievements from './pages/achievements/Achievements';
import TradeLicense from './pages/tradelicense/TradeLicense';
import ScrollToTop from './components/scrolltop/ScrollToTop';
import BookedAmbulance from './pages/bookambulance/BookedAmbulance';
import Search from './pages/search/Search';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/donate" element={<Donate/>} />
        <Route path="/advertise-with-us" element={<AdvertiseWithUs/>} />
        <Route path="/book-ambulance" element={<BookAmbulance/>} />
        <Route path="/register-ambulance" element={<RegisterAmbulance/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/events" element={<Events/>} />
        <Route path="/media" element={<Media/>} />
        <Route path="/achievements" element={<Achievements/>} />
        <Route path="/trade-license" element={<TradeLicense/>} />
        <Route path="/booked-ambulance" element={<BookedAmbulance/>} />
        <Route path="/search" element={<Search/>} />
       
      </Routes>
    
    </Router>
    
  );
};

export default App;