import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    // Add event listener to close the mobile menu when clicking outside
    const handleOutsideClick = (e) => {
      if (isMobileMenuOpen && !e.target.closest('.navbar')) {
        closeMobileMenu();
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleOutsideClick);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMobileMenuOpen]);

  return (
    <nav className="navbar">
      <Link to="/" className="brand" onClick={closeMobileMenu}>
        The HummingBird
      </Link>

      <div className={`menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <Link to="/" onClick={closeMobileMenu}>
          Home
        </Link>

        <Link to="/search" onClick={closeMobileMenu}>
          Search Ambulance
        </Link>

        <Link to="/about" onClick={closeMobileMenu}>
          About
        </Link>

        <Link to="/book-ambulance" onClick={closeMobileMenu}>
          Book Ambulance
        </Link>

        <Link to="/donate" onClick={closeMobileMenu}>
          Donate
        </Link>

        <Link to="/advertise-with-us" onClick={closeMobileMenu}>
          Advertise with Us
        </Link>

        <Link to="/register-ambulance" onClick={closeMobileMenu}>
          Register Ambulance
        </Link>

        <Link to="/contact" onClick={closeMobileMenu}>
          Contact
        </Link>

        {/* <Link to="/events" onClick={closeMobileMenu}>
          Events
        </Link>

        <Link to="/media" onClick={closeMobileMenu}>
          Media
        </Link>

        <Link to="/achievements" onClick={closeMobileMenu}>
          Achievements
        </Link> */}

        <Link to="/book-ambulance" onClick={closeMobileMenu}>
          <button className="book-meeting1">Book Ambulance</button>
        </Link>
      </div>

      <Link to="/book-ambulance">
      <button className="book-meeting">Book Ambulance</button>
        </Link>

      <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
};

export default Navbar;